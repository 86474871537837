import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6} className="d-flex justify-content-end">
            {/* <img src={logo} alt="Logo" /> */}
          </Col>
          <Col size={12} sm={6} className="d-flex justify-content-between text-left text-sm-end">
            <p className="mb-0">Copyright &copy; 2024 by Saloni | All Rights Reserved.</p>
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/saloni-tanmor/"><img src={navIcon1} alt="Icon" /></a>
              <a href="https://github.com/Saloni3494"><img src={navIcon2} alt="Icon" /></a>
              <a href="mailto:saloni.tanmor1@gmail.com"><img src={navIcon3} alt="Icon" /></a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
