import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard_All } from "./ProjectCard_All";
import { ProjectCard_Personal } from "./ProjectCard_Personal";
import { ProjectCard_Client } from "./ProjectCard_Client";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.png";
import projImg5 from "../assets/img/project-img5.png";
import projImg6 from "../assets/img/project-img6.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const allProjects = [
    {
      title: "Chatbot using IBM Cloud",
      description: "Chatbot Development and Deployment on WhatsApp using Twilio.",
      tech: "Tech Stack: Watson Assistant, IBM Cloud, Twilio Integration",
      imgUrl: projImg1,
    },
    {
      title: "SOFIA - An AI Assistant",
      description: "Perform tasks such as controlling smart home devices, answering general knowledge questions, and even integrating with third-party APIs for more functionality.",
      tech:"Tech Stack: Python, eel, Natural Language Processing(NLP), Machine Learning(ML),HTML, CSS, Javascript",
      imgUrl: projImg2,
    },
    {
      title: "Tasty-Trove",
      description: "Powered by PHP, HTML, CSS and MySQL for a seamless user experience.",
      tech: "Tech Stack : [PHP, MySQL, HTML, CSS]",
      imgUrl: projImg3,
    },
    {
      title: "Disney Clone",
      description: "Boosted the understanding of modern web development technologies and best practices.  It is a dynamic full stack website.",
      tech: "Tech Stack : [ React, Firebase, Redux and Styled Components ]",
      imgUrl: projImg4,
    },
    {
      title: "Agrovate",
      description: "A fertilizer prediction site for farmer convinience, easy to use and work in progress.",
      tech: "Tech Stack : [ React, Firebase, SQL, Python]",
      imgUrl: projImg5,
    },
    {
      title: "Student Guard Tracker",
      description: "This project is aimed at seamlessly connecting a robust database with a secure login form to ensure efficient tracking and management of student information.",
      tech: "Tech Stack : [  C++, Microsoft SQL Express, .NET framework]",
      imgUrl: projImg6,
    },
  ];

  const personalProjects = [
    {
      title: "Chatbot using IBM Cloud",
      description: "Chatbot Development and Deployment on WhatsApp using Twilio.",
      tech: "Tech Stack: Watson Assistant, IBM Cloud, Twilio Integration",
      imgUrl: projImg1,
    },
    {
      title: "SOFIA - An AI Assistant",
      description: "Perform tasks such as controlling smart home devices, answering general knowledge questions, and even integrating with third-party APIs for more functionality.",
      tech:"Tech Stack: Python, eel, Natural Language Processing(NLP), Machine Learning(ML),HTML, CSS, Javascript",
      imgUrl: projImg2,
    },
    {
      title: "Tasty-Trove",
      description: "Powered by PHP, HTML, CSS and MySQL for a seamless user experience.",
      tech: "Tech Stack : [PHP, MySQL, HTML, CSS]",
      imgUrl: projImg3,
    },
    {
      title: "Disney Clone",
      description: "Boosted the understanding of modern web development technologies and best practices.  It is a dynamic full stack website.",
      tech: "Tech Stack : [ React, Firebase, Redux and Styled Components ]",
      imgUrl: projImg4,
    },
    {
      title: "Student Guard Tracker",
      description: "This project is aimed at seamlessly connecting a robust database with a secure login form to ensure efficient tracking and management of student information.",
      tech: "Tech Stack : [  C++, Microsoft SQL Express, .NET framework]",
      imgUrl: projImg6,
    },
  ];

  const clientProjects = [
   
  ]

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2><br></br>
                
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">All Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Personal Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Client Projects</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          allProjects.map((allProjects, index) => {
                            return (
                              <ProjectCard_All
                                key={index}
                                {...allProjects}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          personalProjects.map((personalProjects, index) => {
                            return (
                              <ProjectCard_Personal
                                key={index}
                                {...personalProjects}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        <p>Under Construction</p>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
