import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";

export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
      });
  };

  const clearFields = () => {
    setEmail("");
  };

  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row>
          <Col lg={12} md={6} xl={5}>
            <h3>
              Want to build up projects?<br></br> Drop me an email:
            </h3>
            {status === "sending" && <Alert>Sending...</Alert>}
            {status === "error" && <Alert variant="danger">{message}</Alert>}
            {status === "success" && <Alert variant="success">{message}</Alert>}
          </Col>
          <Col md={6} xl={7} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <a 
              href="mailto:saloni.tanmor1@gmail.com"
              style={{ textDecoration: 'none', color: 'inherit' }} // Remove underline and inherit color
            >
              <div className="new-email-bx" style={{ textAlign: 'center' }}>
                saloni.tanmor1@gmail.com
              </div>
            </a>
            
          </Col>
        </Row>
      </div>
    </Col>
  );
};
