import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import saloniImg from "../assets/img/saloni-4.jpg";


import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(150 - Math.random() * 50); // Faster typing speed
  const [index, setIndex] = useState(1);
  const toRotate = [ "Freelancer", "Full Stack Developer", "AI/ML Developer" ];
  const period = 1000; // Faster transition time between texts

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(300); // You can adjust this as needed
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">Saloni's Portfolio</span>
                <h1>{`Hi! I'm Saloni Tanmor`}<br></br> <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Freelancer", "Full Stack Developer", "AI/ML Developer" ]'><span className="wrap">{text}</span></span></h1><br></br>
                  <p>An enthusiast computer graduate, thrive in collaborative environments where innovation and creativity is valued.<br></br><br></br>
                          Strong Communication Skills, and a Proven Track Record in Frontend, Backend, and Software Development. A Dedicated Problem Solver and Collaborative Team Player. Technology excites me and I am always in awe of the change it drives in the world.<br></br><br></br>
                          Technical Proficiency:<br></br>
                          🔹Languages: Proficient in Python, PHP, Java, C and C++<br></br>
                          🔹Frameworks: React.js, .NET<br></br>
                          🔹Database: Skilled in working with SQL (MySQL)<br></br>
                          🔹Tools & Technologies: Git, AWS, Google Cloud, IBM Cloud, Machine Learning<br></br><br></br>
                          And what I might lack in skills I make up for with my determination to learn.</p>
                  <button onClick={() => console.log('connect')}>Let’s Connect <ArrowRightCircle size={25} /></button>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={saloniImg} alt="Saloni's image"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
